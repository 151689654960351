import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import Connector from "../../../service/signalR/signalr-connection";
import { ICustomer } from "../../models/customer/customer.model";
import { convertPartners } from "../../models/shared/signalR/signalRMessage.model";
import { useIsAuthenticated } from "@azure/msal-react";
import {
  SignalRMessageWithTarget,
  Message,
} from "../../models/shared/signalR/signalRMessageWithTarget.model";
import {
  INotificationBaseData,
  INotificationCustomerData,
  INotificationErrorData,
} from "../../models/shared/signalR/signalRData.model";
import { SignalRTargets } from "../../models/shared/signalR/enum/signalRTargets";
import { signalRConstants } from "../../../constants/global.constants";
import { INotificationErrorModel } from "../../models/shared/signalR/signalRError.model";
import { INotificationCustomerModel } from "../../models/shared/signalR/customer/signalRCustomer.model";
import { INotificationVesselModel } from "../../models/shared/signalR/signalRVessel.model";
import { ErrorDetail } from "../../models/shared/error/errorDetail.model";
import { INotificationVesselProcessingModel } from "../../models/shared/signalR/signalRVesselProcessing.model";
import { INotificationVesselProcessingData } from "../../models/shared/signalR/signalRData.model";

interface SignalRContextType {
  signalRData: INotificationBaseData<any> | null;
}

export const SignalRContext = createContext<SignalRContextType | undefined>(
  undefined
);

export const useSignalRService = () => {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error("useSignalRService must be used within a SignalRProvider");
  }
  return context;
};

export const SignalRProvider = ({ children }: { children: ReactNode }) => {
  const [signalRData, setSignalRData] =
    useState<INotificationBaseData<any> | null>(null);
  const isAuthenticated = useIsAuthenticated();
  const debounceMapRef = useRef<
    Map<string, { timeout: NodeJS.Timeout; message: INotificationCustomerData }>
  >(new Map());

  const extractActionType = (headers: any[]): string => {
    const header = headers.find((h) => h.Key === signalRConstants.CCACTIONTYPE);
    return header ? header.StringValue : "";
  };

  const initializeConnector = async () => {
    if (isAuthenticated) {
      const connector = await Connector.getInstance();

      connector.setEventHandlers((message: SignalRMessageWithTarget) => {
        try {
          if (message.Target === SignalRTargets.TenantUpdate) {
            const signalRMessage: Message = message.Message;

            const actionType = extractActionType(
              signalRMessage.Header.CustomHeader
            );

            const data: INotificationCustomerModel = {
              clientId: signalRMessage.Content.ClientId,
              action: actionType,
              customer: {
                id: signalRMessage.Content.CcTenantId,
                name: signalRMessage.Content.TenantName,
                client_tenant_id: signalRMessage.Content.ClientTenantId,
                partners: convertPartners(signalRMessage.Content.Partners),
              } as ICustomer,
            };

            const signalRDataNew: INotificationCustomerData = {
              Target: message.Target,
              Data: data,
            };

            const customerId = data.customer.id;
            const existingEntry = debounceMapRef.current.get(customerId);

            if (existingEntry) {
              clearTimeout(existingEntry.timeout);
            }

            const timeout = setTimeout(() => {
              const latestEntry = debounceMapRef.current.get(customerId);
              if (latestEntry) {
                setSignalRData(latestEntry.message);
                debounceMapRef.current.delete(customerId);
              }
            }, 1000);

            debounceMapRef.current.set(customerId, {
              timeout,
              message: signalRDataNew,
            });
          } else if (
            message.Target === SignalRTargets.VesselDlqBlobTargetName ||
            message.Target === SignalRTargets.VesselDlqParserTargetName ||
            message.Target === SignalRTargets.VesselDlqPersistTargetName
          ) {
            const signalRMessage: Message = message.Message;

            console.log(message);
            const data: INotificationErrorModel = {
              clientId: signalRMessage.Header?.InnerHeader?.SourceApp?.Id ?? "",
              customerId: signalRMessage.Header?.ClientTenant.Id ?? "",
              errors: signalRMessage.Error?.Errors?.map(
                (x: ErrorDetail) => x.Message
              ),
            };

            const signalRDataNew: INotificationErrorData = {
              Target: message.Target,
              Data: data,
            };

            setSignalRData(signalRDataNew);
          } else if (
            message.Target === SignalRTargets.VesselQueueResponseTargetName
          ) {
            const signalRMessage: Message = message.Message;

            const data: INotificationVesselModel = {
              userId: signalRMessage.Header?.CustomHeader?.find((item:any) => item.Key === 'UserId')?.StringValue ?? "",
              clientId: signalRMessage.Header?.InnerHeader?.SourceApp?.Id ?? "",
              customerId: signalRMessage.Header?.ClientTenant.Id ?? "",
              errors: signalRMessage.Error?.Errors?.map((x: any) => x.Message),
              vessel: signalRMessage.Content?.Vessel ?? null,
            };

            const signalRDataNew: INotificationErrorData = {
              Target: message.Target,
              Data: data,
            };

            setSignalRData(signalRDataNew);
          } else if (
            message.Target === SignalRTargets.VesselProcessingStatusTargetName
          ) {
            const signalRMessage: Message = message.Message;

            const data: INotificationVesselProcessingModel = {
              userId: signalRMessage.Header?.CustomHeader?.find((item:any) => item.Key === 'UserId')?.StringValue ?? "",
              clientId: signalRMessage.Header?.SourceApp?.Id ?? "",
              customerId: signalRMessage.Header?.ClientTenant.Id ?? "",
              vesselName: signalRMessage.Content?.VesselName ?? "",
              vesselImoNumber: signalRMessage.Content?.VesselImoNumber ?? "",
              vesselState: signalRMessage.Content?.VesselState ?? "",
              errorMessage: signalRMessage?.Error ?? "",
              timestamp: signalRMessage.Content?.TimeStamp ?? ""
            }

            const signalRDataNew: INotificationVesselProcessingData = {
              Target: message.Target,
              Data: data,
            };

            setSignalRData(signalRDataNew);
          }
        } catch (error) {
          console.error("Error parsing SignalR message:", error);
        }
      });
    }
  };

  useEffect(() => {
    initializeConnector();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <SignalRContext.Provider value={{ signalRData }}>
      {children}
    </SignalRContext.Provider>
  );
};
