import { BaseService } from "../BaseService";
import { headerKey, paths } from "./syncService.constants";
import { SessionUtility } from "../../../utils/data/session.utility";
import { UserUtility } from "../../../utils/data/user.utility";
import { headerKey as generalHeaderKey } from "../common.constants";

export class SyncService extends BaseService {
  public async getSyncClientService(clientId: string | null): Promise<any> {
    const response = await this.get<any>(paths.ONBOARDING_MIDDLEWARE_SYNC_CLIENT, {
      headers: {
      [headerKey.CC_SYNC_CLIENT]: clientId,
      [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
      [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId(),
      },
    });

    return response;
  }
}