import {
    Box,
    Button,
    Checkbox,
    Divider
} from "@mui/material";
import React, { useState, ChangeEvent } from "react";
import {theme} from "../../../../../../styles/theme";
import ClearIcon from '@mui/icons-material/Clear';

interface ComponentProperties {
    vesselGroupingTypes: Array<string>;
    selectedVesselGroupingTypes: Array<string>;
    setSelectedVesselGroupingTypes: (selectedTypes: Array<string>) => void;
}

export const VesselGroupingTypeFilter = (props: ComponentProperties) => {
    const initState: Record<string, boolean> = {};
    props.vesselGroupingTypes.map((vesselGroupingType) => initState[vesselGroupingType] = props.selectedVesselGroupingTypes.includes(vesselGroupingType));
    const [checkedItems, setCheckedItems] = useState(initState);

    const clearFilterClickHandler = (_: any) => {
        const clearedState: Record<string, boolean> = {};
        props.vesselGroupingTypes.map((vesselGroupingType) => clearedState[vesselGroupingType] = false);
        setCheckedItems(clearedState);
        props.setSelectedVesselGroupingTypes([]);
    }

    const groupingFilterChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        const updatedCheckedItems = { ...checkedItems, [value]: checked };
        setCheckedItems(updatedCheckedItems);

        if (checked) {
            if(!props.selectedVesselGroupingTypes.includes(value)) {
                props.setSelectedVesselGroupingTypes([...props.selectedVesselGroupingTypes, value]);
            }
        } else {
            props.setSelectedVesselGroupingTypes(props.selectedVesselGroupingTypes.filter((item) => item !== value));
        }
    }

    return (
        <>
            {props.vesselGroupingTypes.map((vesselGroupingType, index) => (
                <Box key={`vessel-grouping-type-filter-container-${index}`}>
                    <Checkbox
                        key={`vessel-grouping-type-filter-${index}`}
                        value={vesselGroupingType}
                        checked={checkedItems[vesselGroupingType]}
                        onChange={groupingFilterChangeHandler}
                    />
                    <span>{vesselGroupingType}</span>
                </Box>
            ))}
            <Divider />
            <Box sx={{textAlign: "right", padding: "5px"}}>
                <Button
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.colors.blueGray,
                    }}
                    startIcon={<ClearIcon />}
                    disabled={props.selectedVesselGroupingTypes.length === 0}
                    id="clearCategoryFilterButton"
                    onClick={clearFilterClickHandler}
                >
                    {"Clear"}
                </Button>
            </Box>
        </>
    );
};

export default VesselGroupingTypeFilter;
