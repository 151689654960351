import { Typography } from "@mui/material";

interface StyledHeaderWithoutDividerProps {
  label: string;
}

export const StyledHeaderWithoutDivider: React.FC<StyledHeaderWithoutDividerProps> = ({ label }) => {
  return (
    <>
      <Typography variant="h2" component="h2">
        {label}
      </Typography>
    </>
  );
};