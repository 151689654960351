import { VesselGrouping } from "../../../shared/models/vessel/vesselGrouping.model"
import { VesselGroupingModel } from "../../../shared/models/vessel/modelVesselGrouping.model"
import { BaseService } from "../BaseService";
import { headerKey, path } from "./vesselBaseService.constants";
import { headerKey as generalHeaderKey } from "../common.constants";
import { SessionUtility } from "../../../utils/data/session.utility";
import { UserUtility } from "../../../utils/data/user.utility";
import { appConfig } from "../../../config/configLoader";

export class VesselGroupingService extends BaseService {

  public async getGroupingItemsAsync(
    tenantId: string,
    clientId: string | null
  ): Promise<VesselGrouping[]> {
    let filteredGroupingItems: VesselGrouping[] = [];
    let responseData = null;

    if(!clientId || !tenantId) {
      return filteredGroupingItems;
    }

    let vesselGroupingItems: VesselGroupingModel[] = [];
    responseData = await this.get<VesselGroupingModel[]>(
        path.VESSEL_GROUPING_CATALOG,
        {
          headers: {
            [headerKey.CC_ACTIVE_CLIENT]: appConfig.MSAL_CONFIG_CLIENT_ID,
            [headerKey.CC_TENANT_ID]: tenantId,
            [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
            [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        }
    );

    vesselGroupingItems = responseData?.data;

    vesselGroupingItems?.forEach((item) => {
      const vesselGrouping: VesselGrouping = {
        id: item.id,
        type: item.groupingType,
        name: item.groupingName,
        description: item.groupingDescription,
        category: item.groupingCategoryName,
        order: item.groupingOrder
      } as VesselGrouping;
      filteredGroupingItems.push(vesselGrouping);
    });

    return filteredGroupingItems;
  }

  public async postGroupingAsync(
      tenantId: string,
      clientId: string | null,
      vesselGroupings: Array<VesselGroupingModel>
  ): Promise<boolean> {

    if (!clientId || !tenantId) {
      return false;
    }

    try {
      await this.post<VesselGroupingModel[]>(
          path.VESSEL_GROUPING_CATALOG,
          vesselGroupings,
          {
            headers: {
              [headerKey.CC_ACTIVE_CLIENT]: appConfig.MSAL_CONFIG_CLIENT_ID,
              [headerKey.CC_TENANT_ID]: tenantId,
              [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
              [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
            }
          }
      );

      return true;
    } catch(error) {
      return false;
    }
  }
}