export enum VesselProcessingStatus {
  Unknown = 0,
  Pending = 1,
  FailedGroupValidation = 2,
  VesselGroupsValidated = 3,
  Failed = 4,
  ReadyToSubmit = 5,
  Submitted = 6,
  Accepted = 7,
  Rejected = 8,
  Saving = 9,
  Persisted = 10 // Vessels with this status should be removed from the list
}

export function GetEnumValueFromString(enumObj: any, value: string): VesselProcessingStatus | undefined {
  return enumObj[value as keyof typeof enumObj];
}

export function GetVesselProcessingStatusDetails(status: string): StatusDetails {
  let enumValue = GetEnumValueFromString(VesselProcessingStatus, status);
  if (enumValue === undefined) {
    enumValue =  VesselProcessingStatus.Unknown;
  }
  return VesselProcessingStatusDetails[enumValue];
}

type StatusDetails = {
  FullName: string;
  Color: string;
};

export const VesselProcessingStatusDetails: Record<
  VesselProcessingStatus,
  StatusDetails
> = {
  [VesselProcessingStatus.Unknown]: {
    FullName: "Unknown",
    Color: "#EFF1F3",
  },
  [VesselProcessingStatus.Pending]: {
    FullName: "Pending",
    Color: "#BCA634",
  },
  [VesselProcessingStatus.FailedGroupValidation]: {
    FullName: "Failed Group Validation",
    Color: "#E5181B",
  },
  [VesselProcessingStatus.VesselGroupsValidated]: {
    FullName: "Vessel Groups Validated",
    Color: "#43B241",
  },
  [VesselProcessingStatus.Failed]: {
    FullName: "Failed",
    Color: "#E5181B",
  },
  [VesselProcessingStatus.ReadyToSubmit]: {
    FullName: "Ready to Submit",
    Color: "#43B241",
  },
  [VesselProcessingStatus.Submitted]: {
    FullName: "Submitted",
    Color: "#BCA634",
  },
  [VesselProcessingStatus.Accepted]: {
    FullName: "Accepted",
    Color: "#43B241",
  },
  [VesselProcessingStatus.Rejected]: {
    FullName: "Rejected",
    Color: "#43B241",
  },
  [VesselProcessingStatus.Saving]: {
    FullName: "Saving",
    Color: "#43B241",
  },
  [VesselProcessingStatus.Persisted]: {
    FullName: "Persisted",
    Color: "#43B241",
  }
};

export {};