import { BaseService } from "../BaseService";
import { headerKey, path } from "./vesselBaseService.constants";
import { headerKey as generalHeaderKey } from "../common.constants";
import { UserUtility } from "../../../utils/data/user.utility";
import { SessionUtility } from "../../../utils/data/session.utility";
//import { VesselProcessingState } from "../../../shared/models/vessel/vesselProcessingState.model"
import { ResponseMessage } from "../../../shared/models/shared/error/errorResponse.model";
import { CRUDResponse } from "../../../shared/models/shared/CRUDResponse.model";
import i18n from "i18next";
import { VesselProcessingAction } from "../../../shared/models/vessel/vesselProcessingAction.enum";
import { EnumUtility } from "../../../utils/enum/enum.utility";

export class PersistRawVesselFileAdapterService extends BaseService {
  public async persistRawVesselFileAdapterAsync(
    clientId: string,
    tenantId: string,
	action: VesselProcessingAction,
	file: File,
	vesselConfig: string
  ): Promise<CRUDResponse<ResponseMessage>> {

	let crudResponse: CRUDResponse<ResponseMessage> = {
		success: [],
		errors: [],
	};

	try {
		
		const formData = new FormData();
		formData.append("file", file);
		formData.append("FileAction", EnumUtility.GetEnumMemberName(VesselProcessingAction, action) ?? "");
		formData.append("VesselConfig", vesselConfig);
  
		let response = await this.post<ResponseMessage>(
		  path.PERSIST_RAW_VESSEL_FILE_ADAPTER,
		  formData,
		  {
			headers: {
				[headerKey.CC_ACTIVE_CLIENT]: clientId, //"a9896440-da71-44ac-967a-b40936ac1746", //clientId,
				[headerKey.CC_TENANT_ID]: tenantId,
				[generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
				[generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId(),
				"Content-Type": "multipart/form-data",
			},
		  }
		);
  
		if (this.isResponseSuccess(response)) {
		  crudResponse.success.push(
			i18n.t("service.api.request_has_been_submited_successfully")
		  );
		}
	  } catch (error: any) {

		if (error.response) {
		  let message: any = error?.response?.data;
  
		  if (message?.errors && Array.isArray(message.errors)) {
			crudResponse.errors = message.errors.map((obj: any) => obj.message);
		  } else {
			crudResponse.errors.push("An unknown error occurred.");
		  }
		} else {
		  crudResponse.errors.push(
			error?.message?.toString() || "An unknown error occurred."
		  );
		}
	  }

	  return crudResponse;
  }
}