import {Box, styled, Tab, Tabs} from "@mui/material";
import {theme} from "../../styles/theme";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, Outlet} from "react-router-dom";
import { useTranslation } from "react-i18next";

const vesselsRoutePath: string = "/vessel"
const vesselsParentRoutePath: string = `${vesselsRoutePath}/`;
const vesselGroupingCatalogRoutePath: string = `${vesselsRoutePath}/grouping-catalog`;
const vesselManagementRoutePath: string = `${vesselsRoutePath}/management`;

const StyledTabs = styled(Tabs)(() => ({
  "& .MuiTabs-scroller": {
    display: "flex"
  }
}));

export function VesselRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const [locationPathname, setLocationPathname] = useState<string|null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if(location.pathname.toLowerCase().indexOf(vesselsParentRoutePath) === 0) {
        setLocationPathname(location.pathname);
    }
  }, [location.pathname]);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%"
      }}
    >
      <Box>
        <StyledTabs
            value={locationPathname ?? false}
            onChange={handleChange}
            sx={{
              display: "inline-block",
              color: theme.palette.primary.light,
              backgroundColor: theme.background.componentLight,
              marginLeft: "-24px"
            }}
        >
          <Tab
              label={t("routes.vessels.vessel_grouping_catalog")}
              value={vesselGroupingCatalogRoutePath}
              id="VesselGroupingCatalogTab"
              sx={{ width: "auto" }}
          />
          <Tab
              label={t("routes.vessels.vessels_management")}
              value={vesselManagementRoutePath}
              id="VesselManagement"
              sx={{ width: "auto" }}
          />
        </StyledTabs>
      </Box>
      <Outlet />
    </Box>
  );
}