import { theme } from "../../../../styles/theme";
import Box from "@mui/material/Box";
import { ListCustomerView } from "../../customer/list/listCustomerView.component";

export const LeftPanel = () => {
  return (
    <>
      <Box
       sx={{
        display: "flex",
        width: "20%",        
        flexDirection: "column",   
        boxSizing: "border-box",        
        backgroundColor: theme.background.component ,//theme.background.componentLight, 
        height: "100%",
        overflowY: "auto",
               
      }}        
      >                    
          <ListCustomerView />       
      </Box>
    </>
  );
};
