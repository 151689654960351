import { storageConst } from "../../constants/global.constants";

export class UserUtility {
  public static GetUserId(): string {
    let userId = localStorage.getItem(storageConst.USER_ID);
    return userId ?? "undefined";
  }

  public static SetUserId(userId: string): void {
    localStorage.setItem(storageConst.USER_ID, userId ?? "");
  }

  public static IsSelectedUserId(userId: string): boolean {
    return UserUtility.GetUserId() === userId;
  }
}