import {
    Box,
    Button,
    Checkbox,
    Divider,
    InputAdornment, styled,
    TextField
} from "@mui/material";
import React, {useState, ChangeEvent} from "react";
import {theme} from "../../../../../../styles/theme";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';

const backgroundColor = "#141D27";
const borderColor = "#E8ECED";
const borderWidth = "1px";
const textColor = theme.palette.text.primary;

const TextFieldCustom = styled(TextField)({
    "& .MuiInputBase-root": {
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "7px",
        "& fieldset": {
            borderColor: borderColor,
            borderWidth: borderWidth,
        }
    },
    "& .MuiOutlinedInput-root": {
        "& input": {
            padding: "10px"
        },
        "&.Mui-focused fieldset": {
            borderColor: borderColor,
        },
        color: textColor,
        backgroundColor: backgroundColor,
    },
    "& .MuiInputBase-input": {
        color: textColor,
    }
});

interface ComponentProperties {
    vesselGroupingCategories: Array<string>;
    selectedVesselGroupingCategories: Array<string>;
    setSelectedVesselGroupingCategories: (selectedCategories: Array<string>) => void;
}

export const VesselGroupingCategoryFilter = (props: ComponentProperties) => {
    const initState: Record<string, boolean> = {};
    props.vesselGroupingCategories.map((vesselGroupingCategory) => initState[vesselGroupingCategory] = props.selectedVesselGroupingCategories.includes(vesselGroupingCategory));
    const [checkedItems, setCheckedItems] = useState(initState);
    const [vesselGroupingCategories, setVesselGroupingCategories] = useState<string[]>(props.vesselGroupingCategories);
    const [searchText, setSearchText] = useState("");

    const clearFilterClickHandler = (_: any) => {
        const clearedState: Record<string, boolean> = {};
        props.vesselGroupingCategories.map((vesselGroupingCategory) => clearedState[vesselGroupingCategory] = false);
        setCheckedItems(clearedState);
        setSearchText("");
        props.setSelectedVesselGroupingCategories([]);
    }

    const handleFilterSearchChange = (event: any) => {
        const searchTextInput = event.target.value;
        if (searchTextInput) {
            const searchResult = props.vesselGroupingCategories.filter((category) => category.toLowerCase().indexOf(searchTextInput.toLowerCase()) > -1)
            setVesselGroupingCategories(searchResult);
        } else {
            setVesselGroupingCategories(props.vesselGroupingCategories);
        }
        setSearchText(searchTextInput);
    };

    const isClearButtonDisabled = (): boolean => {
        return props.selectedVesselGroupingCategories.length === 0 && searchText.length === 0
    };

    const groupingFilterChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        const updatedCheckedItems = { ...checkedItems, [value]: checked };
        setCheckedItems(updatedCheckedItems);

        if (checked) {
            if (!props.selectedVesselGroupingCategories.includes(value)) {
                props.setSelectedVesselGroupingCategories([...props.selectedVesselGroupingCategories, value]);
            }
        } else {
            props.setSelectedVesselGroupingCategories(props.selectedVesselGroupingCategories.filter((item) => item !== value));
        }
    }

    return (
        <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
            <Box key={`vessel-grouping-category-filter-search-container`}>
                <TextFieldCustom
                    variant="outlined"
                    key={`vessel-grouping-category-filter-search-textfield`}
                    label={searchText ? "": "Search"}
                    value={searchText}
                    onChange={handleFilterSearchChange}
                    InputLabelProps={{
                        shrink: false
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            {vesselGroupingCategories.map((vesselGroupingCategory, index) => (
                <Box key={`vessel-grouping-category-filter-container-${index}`}>
                    <Checkbox
                        key={`vessel-grouping-category-filter-${index}`}
                        value={vesselGroupingCategory}
                        checked={checkedItems[vesselGroupingCategory]}
                        onChange={groupingFilterChangeHandler}
                    />
                    <span>{vesselGroupingCategory}</span>
                </Box>
            ))}
            <Divider />
            <Box sx={{ textAlign: "right", padding: "5px" }}>
                <Button
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.colors.blueGray,
                    }}
                    startIcon={<ClearIcon />}
                    disabled={isClearButtonDisabled()}
                    id="clearCategoryFilterButton"
                    onClick={clearFilterClickHandler}
                >
                    {"Clear"}
                </Button>
            </Box>
        </Box>
    );
};

export default VesselGroupingCategoryFilter;
