import { readSheetNames } from 'read-excel-file';
import { VesselParseResult } from '../../shared/models/shared/parseExcelFile/vessel.model';
import i18n from "i18next";

const requiredSheetNames = [
  "Details",
  "Performance",
  "PowerFuelCharacteristics",
  "SpeedPowerCharacteristics",
  "SpeedResistanceCharacteristics",
  "WindPropulsion",
  "CaaList",
  "Engine",
  "RpmTierList",
  "SFocList",
  "VesselGrouping"
];

export const parseVesselFile = async (file: File): Promise<VesselParseResult> => {
  let result: VesselParseResult = {
    Errors: [],
  };

  try {
    if (file && !(file.name.toLowerCase().endsWith(".xlsx") || file.name.toLowerCase().endsWith(".xlsm"))) {
      result.Errors.push(i18n.t("service.parseexcelfile.please_upload_a_file_with_xlsx_or_xlsm_extension"));
      return result;
    }

    const sheets = await readSheetNames(file);

    const missingSheets = requiredSheetNames.filter(
      (sheetName) => !sheets.includes(sheetName)
    );

    if (missingSheets.length > 0) {
      missingSheets.forEach((sheetName) => {
        result.Errors.push(
          i18n.t("service.parseexcelfile.excel_file_does_not_contain_sheet", {
            sheet: sheetName,
          })
        );
      });
      return result;
    }
  } catch (error: any) {
    result.Errors.push(
      i18n.t("service.parseexcelfile.error_parsing_excel_file") +
        "\n" +
        error.message
    );
  }

  return result;
};