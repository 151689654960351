import { Button } from "@mui/material";
import { theme } from "../../../../styles/theme";
import { useTranslation } from "react-i18next";
import { useSelectedCustomer } from "../../../context/customer/customerSelection.Context";
import { ISelectedCustomer } from "../../../context/customer/selectedCustomer.context.model";
import { Partners } from "../../../models/shared/partner/partners.model";
import { useNavigate } from "react-router-dom";

export const AddCustomerButton = () => {
  const { selectCustomer } = useSelectedCustomer();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickDetailViewBtn = () => {

    navigate("/");

    const defaultCustomerData: ISelectedCustomer = {
      id: "",
      name: "",
      client_tenant_id: "",
      partners: {} as Partners,
    };
    selectCustomer(defaultCustomerData);
  };

  return (
    <Button
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
        width: "100%"
      }}
      onClick={handleClickDetailViewBtn}
      id="AddCustomerButton"
    >
      {t("component.leftpanel.add_customer")}
    </Button>
  );
};
