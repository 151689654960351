import { createBrowserRouter, Navigate } from "react-router-dom";
import { Login } from "../routes/login/login.route";
import { Root } from "../routes/root/root.route";
import { GeneralRoute } from "../routes/general/general.route";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { RestrictedRouter } from "./restricted.router";
import { ErrorPage } from "../shared/components/error/errorPage.component";
import { VesselRoute } from "../routes/vessels/vessels.route";
import { PortRoute } from "../routes/ports/ports.route";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorLog } from "../shared/models/shared/error-log/errorLog.model";
import { logError } from "../service/error/errorService";
import { VesselManagementRoute } from "../routes/vessels/vesselsManagement.route";
import { VesselGroupingCatalogRoute } from "../routes/vessels/vesselsGroupingCatalog.route";

export interface RouteItem {
  path: string;
  breadcrumbName?: string;
}

export const ROUTES = {
  general: { path: "/", breadcrumbName: "General" },
  login: { path: "/login", breadcrumbName: "" },
  vessel: { path: "/vessel", breadcrumbName: "Vessels",
    children: {
      groupingCatalog: { path: "grouping-catalog" },
      management: { path: "management" }
    }
  },
  port: { path: "/port", breadcrumbName: "Ports" },
};

const rootWithComponent = (
  component: EmotionJSX.Element
  //   requiredModules: PermissionModule[]
): EmotionJSX.Element => {
  return (
    <RestrictedRouter
      //   requiredModules={requiredModules}
      childComponent={<Root childComponent={component} />}
    ></RestrictedRouter>
  );
};

const ErrorBoundaryWrapper = (component: EmotionJSX.Element): EmotionJSX.Element => (
  <ErrorBoundary fallback={<ErrorPage />}
    onError={(error, errorInfo) => {
      const errorData: ErrorLog = {
        message: error.message,
        source: errorInfo.componentStack,
        lineno: undefined,
        colno: undefined,
        error,
      };
      
      logError(errorData);
    }}
  >
    {component}

  </ErrorBoundary>
);

export const router = createBrowserRouter([
  {
    path: ROUTES.general.path,
    element: ErrorBoundaryWrapper(rootWithComponent(<GeneralRoute />)),
  },
  {
    path: ROUTES.login.path,
    element: ErrorBoundaryWrapper(<Login />),
  },
  {
    path: ROUTES.vessel.path,
    element: ErrorBoundaryWrapper(rootWithComponent(<VesselRoute />)),
    children: [
      { path: ROUTES.vessel.children.management.path, element: <VesselManagementRoute /> },
      { path: ROUTES.vessel.children.groupingCatalog.path, element: <VesselGroupingCatalogRoute /> }
    ]
  },
  {
    path: ROUTES.port.path,
    element: ErrorBoundaryWrapper(rootWithComponent(<PortRoute />)),
  },
  //Add a catch-all route to redirect to login
  {
    path: "*",
    element: ErrorBoundaryWrapper(<Navigate to={ROUTES.login.path} />),
  },
]);