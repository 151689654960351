import {
  Box,
  Stack,
  styled,
  Typography,
  Tooltip,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { theme } from "../../../../../styles/theme";
import { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { useSelectedClientApp } from "../../../../context/clientApplication/clientApplicationSelection.context";
import { useTranslation } from "react-i18next";
import {
  BrowserType,
  detectBrowser,
} from "../../../../../utils/browser/DetectBrowser";
import { VesselInProcessModel } from "../../../../models/vessel/vesselInProcess.model";
import { GetVesselProcessingStatusDetails } from "../../../../models/vessel/vesselProcessingStatus.enum";

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <UnfoldMoreOutlinedIcon />;
}

const StyledDataGrid = styled(DataGridPro)(() => ({
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiDataGrid-cell": {
    border: "transparent",
  },
  "& .MuiDataGrid-container--top [role=row]": {
    backgroundColor: theme.background.component,
    fontSize: "14px",
  },
  "& .MuiDataGrid-withBorderColor": {
    border: "none",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-container--top::after": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-overlayWrapper": {
    height: "30px",
  },
}));

interface DataGridVesselComponentProps {
  item: VesselInProcessModel[] | [];
}

export const DataGridVesselsInProcess: React.FC<DataGridVesselComponentProps> = ({
  item,
}) => {
  const [data, setData] = useState([] as VesselInProcessModel[]);

  const { selectedClientApp } = useSelectedClientApp();
  const [browser, setBrowser] = useState<string>("");

  const { t } = useTranslation();

  useEffect(() => {
    setBrowser(detectBrowser());
    setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp]);

  useEffect(() => {
    setData(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]); 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: theme.background.component,
        borderRadius: "8px",
        marginTop: "10px",
      }}
    >
      <Box>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            verticalAlign: "middle",
            justifyContent: "space-evenly",
            padding: "5px",
            height: "60px",
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography
              sx={{
                background: theme.background.componentLight,
                borderRadius: "8px",
                fontSize: "20px",
                width: "fit-content",
                height: "100%",
                textAlign: "left",
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: "5px",
                paddingRight: "5px"
              }}
            >
              {t("component.vessel.dataGrid.info.count")}: {data.length}
            </Typography>
          </Box>          
        </Stack>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        height: "32vh",
        margin: 0,
        [theme.breakpoints.down("xl")]: {
          height: browser === BrowserType.CHROME ? "32vh" : "34vh",
        },
        [theme.breakpoints.down("lg")]: {
          height: browser === BrowserType.CHROME ? "22vh" : "24vh",
        },
        [theme.breakpoints.down("mdPlus")]: {
          height: browser === BrowserType.CHROME ? "12vh" : "14vh",
        },
      }}>
        <StyledDataGrid
          sx={{
            border: "none",
            BorderStyle: "none !important",
            rowBorderColor: "red",
          }}
          rows={data}
          getRowId={(row) => row.ImoNumber}
          columns={[
            {
              field: "Name",
              headerName: t("component.vessel.dataGrid.column.name"),
              flex: 1,
            },
            {
              field: "ImoNumber",
              headerName: t("component.vessel.dataGrid.column.imo"),
              flex: 1,
            },
            {
              field: "Status",
              headerName: t("component.vessel.dataGrid.column.status"),
              flex: 1,
              renderCell: (params: any) => {
                const tooltip = params.row?.Error || '';
                const statusText = params.row?.Status || '';
                const vesselProcessingStatusDetails = GetVesselProcessingStatusDetails(statusText);
                return (
                  <Tooltip title={tooltip}>
                    <Box component="span" className="table-cell-truncate" sx={{ color: vesselProcessingStatusDetails.Color }}>
                      {vesselProcessingStatusDetails.FullName}
                    </Box>
                  </Tooltip>
                );
              },
            },
          ]}
          disableRowSelectionOnClick
          hideFooter
          disableColumnMenu
          disableDensitySelector
          slots={{
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
          }}
          localeText={{
            noRowsLabel: t("component.vessel.dataGrid.info.no_data"),
          }}
        />
      </Box>
    </Box>
  );
};

export default DataGridVesselsInProcess;