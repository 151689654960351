import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { PrimaryButton } from "../../styled/button/styledButtonPrimary.component";
import { useTranslation } from "react-i18next";
import { useToastMessageService } from "../../../context/toast/toastMessage.Context";
import { Tooltip } from '@mui/material';
import { theme } from "../../../../styles/theme";

interface UploadFileProps {
  buttonCaption: string;
  allowedFileExtensions: Array<string>;
  onFileSelected: (file: File | null) => void;
  disabled?: boolean;
  reset?: boolean;
  validationFailed?: boolean;
}

export const UploadFile: React.FC<UploadFileProps> = ({ buttonCaption, allowedFileExtensions, onFileSelected, disabled, reset, validationFailed }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToastMessageService();
  const { t } = useTranslation();
  const allowedFileExtensionsString = allowedFileExtensions.map(ext => `.${ext}`).join(',');

  const colorError = theme.palette.error.main;

  useEffect(() => {
    if (reset) {
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [reset]);

  const handleButtonClick = () => {
    if (!disabled) {
      fileInputRef?.current?.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFileExtensions.includes(fileExtension)) {
        addToast("error", t("routes.vessels.invalid_file_format") + "." + t("routes.vessels.please_upload_a_file_with_these_extensions", { extensions: allowedFileExtensionsString }) + "!");
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setSelectedFile(null);
        onFileSelected(null);
        return;
      }
      setSelectedFile(file);
      onFileSelected(file);
    }
  };

  return (
    <Box sx = {{ marginTop: '5px', display: 'flex', alignItems: 'center'}}>
      <Box>
        <PrimaryButton type="submit" variant="contained" onClick={handleButtonClick} sx={{minWidth: '250px'}} disabled={disabled}>{buttonCaption}</PrimaryButton>
        <input
          type="file"
          accept= {allowedFileExtensionsString}
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          disabled={disabled}
        />
      </Box>
      <Tooltip title={selectedFile ? (validationFailed ? t("error.validation_failed") + ": " : "") + selectedFile.name : ""} placement="top">
        <Box
            component="label"
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid white',
              backgroundColor: theme.background.component,
              padding: '4px',
              borderRadius: '4px',
              marginLeft: '8px',
              minHeight: '40px',
              width: '100%',
              overflow: 'hidden',
              color: validationFailed ? colorError : 'inherit'
            }}
          >
            <Box
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                flex: 1,
              }}
            >
              {selectedFile?.name || ""}
            </Box>
          </Box>
      </Tooltip>
    </Box>
  );
};