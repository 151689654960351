import {
    Autocomplete,
    Box,
    Divider,
    MenuItem,
    Select,
    styled,
    Typography
} from "@mui/material";
import React, {
    useEffect,
    useState
} from "react";
import i18n from 'i18next';
import {
    FieldPath,
    FormProvider,
    useForm,
    UseFormGetValues
} from "react-hook-form";
import { theme } from "../../../../../styles/theme";
import { StyledTextField } from "../../../styled/textfield/styledTextField.component";
import { VesselGroupingModel, VesselGroupingType } from "../../../../models/vessel/modelVesselGrouping.model"
import { VesselGrouping, VesselGroupingTypes } from "../../../../models/vessel/vesselGrouping.model"
import { VesselGroupingService } from "../../../../../service/api/vessel/vesselGroupingService";
import { OkCancelModal } from "../../../modal/okCancelModal.component";
import { ButtonTypes } from "../../../../models/shared/button/buttonTypes";
import { useToastMessageService } from "../../../../context/toast/toastMessage.Context";
import { useGlobalProgressBarService } from "../../../../context/globalProgressBar/globalProgressBar.Context";

const textColor = theme.palette.text.primary;
const backgroundColor = "#141D27";
const borderColor = "#E8ECED";

const restrictedCharacters = /[!@#$%^&*]/;
const bySisterClassCategory = "By Sister Class";

const validateRestrictedCharacters = (input: string | null) => {
    return (!restrictedCharacters.test(input ?? "") || `The characters ${restrictedCharacters.toString().slice(2, -2)} are not allowed`);
}

const CustomStyledTextField = styled(StyledTextField)({
    marginTop: "0px",
    marginBottom: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiInputBase-root": {
        marginTop: "0px"
    },
    "& .MuiOutlinedInput-root": {
        paddingTop: "0px",
        paddingBottom: "0px"
    }
})

const SelectCustom = styled(Select)({
    backgroundColor: backgroundColor,
    "& .MuiInputLabel-root": {
        color: textColor,
        WebkitTextFillColor: textColor,
        transform: "translate(14px, -20px) scale(1)"
    },
    "& .MuiOutlinedInput-root": {
        WebkitTextFillColor: textColor,
        color: textColor,
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: borderColor
    },
    "& .MuiInputBase-input": {
        paddingTop: "9px",
        paddingBottom: "9px"
    }
});

const CustomAutocomplete = styled(Autocomplete)({
    "& .MuiInputBase-root": {
        marginTop: "0px"
    },
    "& .MuiFormControl-root": {
        marginTop: "0px"
    },
    "& .MuiOutlinedInput-root": {
        paddingTop: "1px",
        paddingBottom: "2px"
    }
})

export type FormFields = {
    groupingType: VesselGroupingType;
    groupingOrder: number;
    groupingCategory: string;
    groupingName: string | null;
    groupingDescription: string | null;
};

const getDefaultFormData = (): FormFields => {

    let formFields: FormFields;

    formFields = {
        groupingType: "Organization",
        groupingOrder: 1,
        groupingCategory: "",
        groupingName: "",
        groupingDescription: ""
    } as FormFields;

    return formFields;
};

const createGrouping = async (
    clientId: string,
    customerId: string,
    getFormValues: UseFormGetValues<FormFields>,
    vesselGroupingService: VesselGroupingService) : Promise<boolean> => {
    const formFields = getFormValues();

    const vesselGrouping: VesselGroupingModel = {
        groupingType: formFields.groupingType,
        groupingOrder: Number(formFields.groupingOrder),
        groupingName: formFields.groupingName ?? undefined,
        groupingDescription: formFields.groupingDescription ?? undefined,
        groupingCategoryName: formFields.groupingCategory
    };

    return await vesselGroupingService.postGroupingAsync(customerId, clientId, [ vesselGrouping ])
}

const getCategories = (allCategories: string[]): string[] => {
    const categories = Array.from(new Set(allCategories))

    if(!categories.some(item => item.toLowerCase() === bySisterClassCategory.toLowerCase())){
        return categories;
    }

    return [bySisterClassCategory, ...categories.filter(item => item.toLowerCase() !== bySisterClassCategory.toLowerCase())]
}

interface ComponentProperties {
    saveButtonClicked: boolean;
    onSavedHandler: (value: boolean) => void;
    clientId: string;
    customerId: string;
    onCancelHandler: (value: boolean) => void;
    cancelButtonClicked: boolean;
    groupingCategories: string[];
    setRequestInProgress: (value: boolean) => void;
}

export const VesselGroupingDetails = (props: ComponentProperties) => {
    const vesselGroupingService = new VesselGroupingService();
    const { addToast } = useToastMessageService();
    const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();

    const requiredValidationRule = { value: true, message: i18n.t("error.field_is_required")};
    const maxLengthValidationRule = (maxLength: number) => { return { value: maxLength, message: i18n.t("error.field_max_length")}; };

    const [isClearingForm, setIsClearingForm] = useState(false);
    const [formValues, setFormValues] = useState<FormFields>(getDefaultFormData());

    const formMethods = useForm<FormFields>({defaultValues: formValues});
    const {register, reset, formState: { isDirty, errors }, getValues, setValue, trigger} = formMethods;

    const onCategoryBlurHandler = (event: any) => {
        const selectedCategory = event.target.value;

        setValue("groupingCategory", selectedCategory);

        trigger("groupingCategory")
            .then((isValid) => {
                if (isValid) {
                    if (selectedCategory.toLowerCase() === bySisterClassCategory.toLowerCase()) {
                        setValue("groupingOrder", 1);
                    } else {
                        setValue("groupingOrder", getNextCategoryOrder(selectedCategory));
                    }
                }
            })
    }

    const getNextCategoryOrder = (categoryName: string): number => {
        if(!props.groupingCategories.some(item => item.toLowerCase() === categoryName.toLowerCase())){
            return 1;
        }

        return props.groupingCategories.filter(item => item.toLowerCase() === categoryName.toLowerCase()).length + 1;
    }

    const getVesselGroupingAsync = async (
        vesselGroupingType: string,
        vesselGroupingCategory: string,
        vesselGroupingName: string): Promise<VesselGrouping | null> => {

        const gropings = await vesselGroupingService.getGroupingItemsAsync(props.customerId, props.clientId);

        const searchResult: VesselGrouping[] = gropings.filter(
            (item) => item.type.toLowerCase() === vesselGroupingType.toLowerCase() &&
                item.category?.toLowerCase() === vesselGroupingCategory.toLowerCase() &&
                item.name?.toLowerCase() === vesselGroupingName.toLowerCase());

        return searchResult.length > 0 ? searchResult[0] : null;
    }

    const onBlurHandler = (event: any, fieldName: FieldPath<FormFields>) => {
        trigger(fieldName)
            .then((isValid) => {
                if (isValid) {
                    setValue(fieldName, event.target.value);
                }
            })
    };

    const saveVesselGrouping = () => {
        trigger().then(async (isValid) => {
            if (isValid) {
                const saveGrouping = async() => {
                    const type = getValues().groupingType;
                    const category = getValues().groupingCategory;
                    const name = getValues().groupingName ?? "";
                    const vesselGrouping = await getVesselGroupingAsync(type, category, name);

                    if (vesselGrouping === null) {
                        const saveResult = await createGrouping(props.clientId, props.customerId, getValues, vesselGroupingService);
                        if (saveResult) {
                            clearForm();
                            props.onSavedHandler(true);
                        } else {
                            props.onSavedHandler(false);
                        }

                        return saveResult;
                    } else {
                        addToast("error", `The VesselGrouping with type: ${type}, category: ${category} and name: ${name} already exists`);
                        return false;
                    }
                };

                try {
                    props.setRequestInProgress(true);
                    addProgressItem();
                    saveGrouping().then((saveResult) => {
                        if (saveResult) {
                            addToast("success", i18n.t("service.api.request_has_been_submited_successfully"));
                        } else {
                            props.onSavedHandler(false);
                        }
                    })
                } catch (error: any) {
                    addToast("error", i18n.t("service.api.request_failed") + ":\n" + error.message);
                } finally {
                    props.setRequestInProgress(false);
                    removeProgressItem();
                }
            } else {
                props.onSavedHandler(false);
            }
        })
    };

    const setFormFieldsValues = (formValues: FormFields) => {
        setFormValues({...formValues});
    };

    const clearForm = () => {
        let formValues = getDefaultFormData();

        setFormFieldsValues(formValues);

        reset(formValues, { keepDirtyValues: true });
    };

    const clearButtonConfirmationHandler = (buttonType: string) => {
        setIsClearingForm(false);

        if (buttonType === ButtonTypes.Yes) {
            clearForm();
            props.onCancelHandler(true);
        } else {
            props.onCancelHandler(false);
        }
    };

    useEffect(() => {
        if (props.saveButtonClicked) {
            saveVesselGrouping();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saveButtonClicked]);

    useEffect(() => {
        if (props.cancelButtonClicked) {
            if (isDirty) {
                setIsClearingForm(true);
            } else {
                clearForm();
                props.onCancelHandler(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cancelButtonClicked]);

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={e => e.preventDefault()} style={{width: "auto"}}>
                <Typography variant="h2" component="h2" sx={{display: "flex", justifyContent: "space-between"}}>
                    {"Create New Vessel Grouping"}
                </Typography>
                <Divider textAlign="right" sx={{mb: 2}}></Divider>
                <Box
                    textAlign="left"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "10px"
                    }}>
                    <span style={{ width: "10%" }}>Classification</span>
                    <SelectCustom
                        {...register("groupingType", {
                            required: requiredValidationRule
                        })}
                        value={formValues.groupingType}
                        sx={{
                            width: "30%",
                            marginLeft: "5px"
                        }}
                        error={Boolean(errors.groupingType)}
                    >
                        {VesselGroupingTypes.map((groupingType, index) => (
                            <MenuItem value={groupingType} key={`vessel-grouping-type-${index}`}>
                                {groupingType}
                            </MenuItem>
                        ))}
                    </SelectCustom>
                </Box>
                <Box sx={{
                    width: "100%",
                    display: "inline-block",
                    backgroundColor: theme.background.component,
                    borderColor: theme.background.component,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: "6px",
                    padding: "15px 6px 15px 6px"
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between"}}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "50%",
                            alignItems: "center"}}>
                            <span style={{ width: "20%", overflow: "hidden" }}>Category</span>
                            <Box sx={{ width: "70%" }}>
                                <CustomAutocomplete
                                    freeSolo
                                    {...register("groupingCategory", {
                                        required: requiredValidationRule,
                                        maxLength: maxLengthValidationRule(50),
                                        validate: validateRestrictedCharacters
                                    })}
                                    value={formValues.groupingCategory}
                                    options={getCategories(props.groupingCategories)}
                                    onBlur={onCategoryBlurHandler}
                                    renderInput={(params) => <CustomStyledTextField
                                        {...params}
                                        maxLength={50}
                                        error={Boolean(errors.groupingCategory)}
                                        helperText={errors.groupingCategory && errors.groupingCategory.message}
                                    />}
                                />
                            </Box>
                        </Box>
                        <Box sx={{
                                display: "flex",
                                width: "50%",
                                justifyContent: "right",
                                alignItems: "center"
                            }}>
                            <span style={{ width: "10%"}}>Order</span>
                            <CustomStyledTextField
                                sx={{ width: "20%" }}
                                {...register("groupingOrder")}
                                disabled={true}
                            />
                        </Box>
                    </Box>
                    <Box
                        textAlign="left"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "10px"
                        }}>
                        <span style={{ width: "10%" }}>Description</span>
                        <CustomStyledTextField
                            sx={{ width: "90%" }}
                            {...register("groupingDescription", {
                                maxLength: maxLengthValidationRule(400),
                                pattern: {
                                    value: /^[a-zA-Z0-9]*$/,
                                    message: "Only alphanumeric characters are allowed"
                                }
                            })}
                            onBlur={(e: any) => {
                                onBlurHandler(e, "groupingDescription")
                            }}
                            maxLength={400}
                            error={Boolean(errors.groupingDescription)}
                            helperText={errors.groupingDescription && errors.groupingDescription.message}
                        />
                    </Box>
                    <Box
                        textAlign="left"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "10px"
                        }}>
                        <span style={{ width: "10%" }}>Name</span>
                        <CustomStyledTextField
                            sx={{ width: "90%" }}
                            {...register("groupingName", {
                                required: requiredValidationRule,
                                maxLength: maxLengthValidationRule(50),
                                validate: validateRestrictedCharacters
                            })}
                            maxLength={50}
                            onBlur={(e: any) => {onBlurHandler(e, "groupingName")}}
                            error={Boolean(errors.groupingName)}
                            helperText={errors.groupingName && errors.groupingName.message}
                        />
                    </Box>
                </Box>
            </form>
            <OkCancelModal
                isOpen={isClearingForm}
                onButtonClick={clearButtonConfirmationHandler}
                title="Warning"
                message={i18n.t("component.port.message.clean_form_warning")}
            />
        </FormProvider>
    );
};

export default VesselGroupingDetails;
