import {Port, SearchUNPortsResult} from "../../../shared/models/port/port.model";
import {
  Country,
  CreateCustomPort,
  CustomPort,
  Function,
  Status
} from "../../../shared/models/port/protoPort.model";
import { PortResponse } from "../../../shared/models/port/portResponse.model";
import { UnecePort } from "../../../shared/models/port/protoPort.model";
import { BaseService } from "../BaseService";
import { headerKey, paging, path } from "./portService.constants";
import { SessionUtility } from "../../../utils/data/session.utility";
import { UserUtility } from "../../../utils/data/user.utility";
import { headerKey as generalHeaderKey } from "../common.constants";

export class CustomPortService extends BaseService {
  private page_num: number = 0;

  public async postCustomPortAsync(
      tenantId: string,
      clientId: string | null,
      createCustomPort: CreateCustomPort
  ): Promise<boolean> {

    if (!clientId || !tenantId) {
      return false;
    }

    await this.post<CreateCustomPort>(
        path.TENANT_ADAPTERS_CUSTOM_PORTS,
        createCustomPort,
        {
          headers: {
            [headerKey.CC_ACTIVE_CLIENT]: clientId,
            [headerKey.CC_TENANT_ID]: tenantId,
            [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
            [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        }
    );

    return true;
  }

  public async putCustomPortAsync(
      tenantId: string,
      clientId: string | null,
      customPortCode: string,
      updateCustomPort: CustomPort
  ): Promise<boolean> {

    if (!clientId || !tenantId) {
      return false;
    }

    await this.put<CustomPort>(
        `${path.TENANT_ADAPTERS_CUSTOM_PORTS}${customPortCode}`,
        updateCustomPort,
        {
          headers: {
            [headerKey.CC_ACTIVE_CLIENT]: clientId,
            [headerKey.CC_TENANT_ID]: tenantId,
            [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
            [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        }
    );

    return true;
  }

  public async getCustomPortsAsync(
    tenantId: string,
    clientId: string | null
  ): Promise<Port[]> {
    let filteredPorts: Port[] = [];
    let responseData = null;

    if(!clientId || !tenantId) {
      return filteredPorts;
    }

    do {
      let ports: CustomPort[] = [];

      responseData = await this.get<PortResponse>(
        path.TENANT_ADAPTERS_CUSTOM_PORTS,
        {
          headers: {
            [headerKey.CC_ACTIVE_CLIENT]: clientId,
            [headerKey.CC_TENANT_ID]: tenantId,
            [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
            [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          },
          params: {
            page_num: this.page_num,
            page_size: paging.MAX_PAGE_SIZE,
          },
        }
      );

      ports = responseData?.data?.ports as CustomPort[];

      ports?.forEach((item, index) => {
        const customPort: Port = {
          uiId: `UIID_${index}`,
          unLocode: item.port.locode ?? "",
          alias: item.name ?? "",
          name: item.port.name ?? "",
          code: item.code ?? "",
          country: item.port.country?.code ?? "",
          lastChangedDate: item.port.lastChangeDate ?? ""
        } as Port;
        filteredPorts.push(customPort);
      });

      this.page_num++;
    } while (this.NextPageExists(responseData?.data));

    this.resetPageNum();

    return filteredPorts;
  }

  public async getCustomPortAsync(
      tenantId: string,
      clientId: string | null,
      customPortCode: string
  ): Promise<CustomPort | null> {

    if(!clientId || !tenantId) {
      return null;
    }

    const responseData = await this.get<CustomPort>(
        `${path.ONBOARDING_MIDDLEWARE_GET_CUSTOM_PORT}?code=${customPortCode}`,
        {
          headers: {
            [headerKey.CC_TENANT_ID]: tenantId,
            [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
            [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        });

    return responseData.data;
  }

  public async getPortFunctionsAsync() : Promise<Function[]> {
    try {
      const responseData = await this.get<Function[]>(
        path.ONBOARDING_MIDDLEWARE_GET_PORT_FUNCTIONS,
        {
          headers: {
          [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
          [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        }
      );
      return responseData.data;
    }
    catch (e) {
      return [];
    }
  }

  public async getPortStatusesAsync() : Promise<Status[]> {
    try {
      const responseData = await this.get<Status[]>(
        path.ONBOARDING_MIDDLEWARE_GET_PORT_STATUSES,
        {
          headers: {
          [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
          [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        }
      );
      return responseData.data;
    }
    catch (e) {
      return [];
    }
  }

  public async getPortCountriesAsync() : Promise<Country[]> {
    try {
      const responseData = await this.get<Country[]>(
        path.ONBOARDING_MIDDLEWARE_GET_PORT_COUNTRIES,
        {
          headers: {
          [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
          [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        });
      return responseData.data;
    }
    catch (e) {
      return [];
    }
  }

  public async getPortLocodesAsync(countryCode: string, pageSize: number, pageNumber: number) : Promise<string[]> {
    try {
      const responseData = await this.get<string[]>(
        `${path.ONBOARDING_MIDDLEWARE_GET_PORT_LOCODES}?country_code=${countryCode}&page_size=${pageSize}&page_num=${pageNumber}`,
        {
          headers: {
          [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
          [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        });
      return responseData.data;
    }
    catch (e) {
      return [];
    }
  }

  public async searchUnecePortsAsync(
      countryCode: string,
      locode: string,
      name: string,
      sort: string,
      sortDirection: string,
      pageSize: number,
      pageNumber: number) : Promise<SearchUNPortsResult> {
    let ports: Port[] = [];
    let unecePorts: UnecePort[] = [];
    try {
      let unecePortSearchUrl = path.ONBOARDING_MIDDLEWARE_SEARCH_UNECE_PORTS;
      unecePortSearchUrl += `?country_code=${countryCode}&port_code=${locode}&name=${name}`;
      unecePortSearchUrl += `&page_size=${pageSize}&page_num=${pageNumber}`;
      unecePortSearchUrl += `&sort=${sort}&sort_direction=${sortDirection}`;

      const responseData = await this.get<UnecePort[]>(
        unecePortSearchUrl,
        {
          headers: {
          [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
          [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
          }
        });

      unecePorts = responseData?.data as UnecePort[];

      unecePorts?.forEach((item, index) => {
        const port: Port = {
          uiId: `UIID_${index}`,
          unLocode: item.locode ?? "",
          alias: "",
          name: item.name ?? "",
          country: item.country?.code ?? "",
          code: "",
          lastChangedDate: item.lastChangeDate ?? ""
        } as Port;
        ports.push(port);
      });
    }
    catch (e) {
      return { data: [], dataSource: [] } as SearchUNPortsResult;
    }

    return { data: ports, dataSource: unecePorts } as SearchUNPortsResult;
  }

  private resetPageNum(): void {
    this.page_num = 0; // Reset page_num to 0
  }

  private NextPageExists(responseData: PortResponse): boolean {
    return (
      responseData?.paging?.totalCount / responseData?.paging?.pageSize <
      responseData?.paging?.pageNumber
    );
  }
}
