import { BaseService } from "../BaseService";
import { headerKey, path } from "./vesselBaseService.constants";
import { headerKey as generalHeaderKey } from "../common.constants";
import { UserUtility } from "../../../utils/data/user.utility";
import { SessionUtility } from "../../../utils/data/session.utility";
import { VesselProcessingState } from "../../../shared/models/vessel/vesselProcessingState.model"

export class VesselProcessingStateService extends BaseService {
  public async getVesselProcessingStateAsync(
    clientId: string,
    tenantId: string,
  ): Promise<VesselProcessingState[]> {

    const responseData = await this.get<VesselProcessingState[]>(
      path.VESSEL_PROCESSING_STATE,
      {
        headers: {
          [headerKey.CC_ACTIVE_CLIENT]: clientId,
          [headerKey.CC_TENANT_ID]: tenantId,
          [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
          [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId(),
        },
      }
    );

    return responseData.data;
  }
}