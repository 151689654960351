import { List, ListItem } from "@mui/material";
import { useSelectedCustomer } from "../../../context/customer/customerSelection.Context";
import { ISelectedCustomer } from "../../../context/customer/selectedCustomer.context.model";
import { theme } from "../../../../styles/theme";
import { ICustomer } from "../../../models/customer/customer.model";
import { Tenant } from "../../../models/shared/tenant/tenant.model";
import { useToastMessageService } from "../../../context/toast/toastMessage.Context";
import { CustomerService } from "../../../../service/api/customer/customerService";
import { ClientUtility } from "../../../../utils/data/client.utility";
import { useGlobalProgressBarService } from "../../../context/globalProgressBar/globalProgressBar.Context";

interface CustomerListProps {
  data: ICustomer[];
}

export const ListCustomers: React.FC<CustomerListProps> = ({ data }) => {
  const { addToast } = useToastMessageService();
  const { selectCustomer, selectedCustomer } = useSelectedCustomer();
  const customerService = new CustomerService();
  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();

  const handleCustomerClick = async (customer: ISelectedCustomer) => {
    try {
      addProgressItem();
      let selectedClientId = ClientUtility.GetSelectedClientId();
      const response: Tenant = await customerService.getCustomerByClientIdAsync(
        customer.id,
        selectedClientId
      );

      customer.client_tenant_id = response.clientTenantId;
      customer.name = response.tenantName;
      customer.partners = response.partners;
      customer.vessels = response.vessels;

      selectCustomer(customer);
    } catch (error) {
      addToast("error", "Error fetching customer data: " + error);
    } finally {
      removeProgressItem();
    }
  };

  return (
    <List sx={{ width: "100%" }}>
      {data.map((customer) => (
        <ListItem
          key={customer.id}
          onClick={() => handleCustomerClick(customer)}
          sx={{
            cursor: "pointer",
            backgroundColor:
              selectedCustomer?.id === customer.id
                ? theme.palette.primary.dark
                : "",
          }}
          data-id="CustomerListItem"
        >
          {customer.name}
        </ListItem>
      ))}
    </List>
  );
};

export default ListCustomers;
