import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { ISelectedCustomer } from "./selectedCustomer.context.model";
import { useSelectedClientApp } from "../clientApplication/clientApplicationSelection.context";
import { ClientUtility } from "../../../utils/data/client.utility";
import { CustomerService } from "../../../service/api/customer/customerService";
import { Tenant } from "../../models/shared/tenant/tenant.model";
import { useGlobalProgressBarService } from "../globalProgressBar/globalProgressBar.Context";
import { storageConst } from "../../../constants/global.constants";

interface CustomerContextType {
  selectedCustomer: ISelectedCustomer | null;
  selectCustomer: (customer: ISelectedCustomer) => void;
}

export const CustomerContext = createContext<CustomerContextType | undefined>(
  undefined
);

export const useSelectedCustomer = () => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error(
      "useSelectedCustomer must be used within a CustomerProvider"
    );
  }
  return context;
};

export const CustomerProvider = ({ children }: { children: ReactNode }) => {
  const [selectedCustomer, setSelectedCustomer] =
    useState<ISelectedCustomer | null>(null);

  const { selectedClientApp } = useSelectedClientApp();

  const selectCustomer = (customer: ISelectedCustomer) => {
    setSelectedCustomer(customer);
    localStorage.setItem(
      storageConst.SELECTED_CUSTOMER,
      JSON.stringify({ id: customer.id })
    );
    sessionStorage.setItem(
      storageConst.SELECTED_CUSTOMER,
      JSON.stringify({ id: customer.id })
    );
  };

  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();

  const customerService = new CustomerService();

  const [isInitialRender, setIsInitialRender] = useState(true);

  const getCustomerData = async (customer: ISelectedCustomer) => {
    try {
      let selectedClientId = ClientUtility.GetSelectedClientId();

      addProgressItem();
      const response: Tenant = await customerService.getCustomerByClientIdAsync(
        customer.id,
        selectedClientId
      );

      if (response !== null) {
        customer.name = response.tenantName;
        customer.client_tenant_id = response.clientTenantId;
        customer.partners = response.partners;
        customer.vessels = response.vessels;

        setSelectedCustomer(customer);
      } else {
        setSelectedCustomer(null);
      }
    } catch (error) {
      setSelectedCustomer(null);
    } finally {
      removeProgressItem();
    }
  };

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    const savedCustomer = localStorage.getItem(
      storageConst.SELECTED_CUSTOMER
    );
    if (savedCustomer) {
      getCustomerData(JSON.parse(savedCustomer));
      sessionStorage.setItem(storageConst.SELECTED_CUSTOMER, savedCustomer);
    } else {
      setSelectedCustomer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp]);

  return (
    <CustomerContext.Provider value={{ selectedCustomer, selectCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};
