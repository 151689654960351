import { Box, ButtonGroup, Button, Typography } from "@mui/material";
import { UploadFile } from "../../shared/components/vessel/management/uploadFile.component";
import { StyledHeaderWithoutDivider } from "../../shared/components/styled/header/styledHeaderWithoutDivider.component";
import DataGridVessel from "../../shared/components/vessel/management/grid/dataGridVessel.component";
import DataGridVesselsInProcess from "../../shared/components/vessel/management/grid/dataGridVesselsInProcess.component";
import { useSignalRService } from "../../shared/context/signalR/signalR.Context";
import { useEffect, useRef, useState } from "react";
import { SignalRTargets } from "../../shared/models/shared/signalR/enum/signalRTargets";
import { ClientUtility } from "../../utils/data/client.utility";
import { useToastMessageService } from "../../shared/context/toast/toastMessage.Context";
import { Vessel } from "../../shared/models/shared/vessel/vessel.model";
import { CustomerUtility } from "../../utils/data/customer.utility";
import { useTranslation } from "react-i18next";
import { useSelectedClientApp } from "../../shared/context/clientApplication/clientApplicationSelection.context";
import { useSelectedCustomer } from "../../shared/context/customer/customerSelection.Context";
import { PrimaryButton } from "../../shared/components/styled/button/styledButtonPrimary.component";
import { theme } from "../../styles/theme";
import { VesselInProcessModel } from "../../shared/models/vessel/vesselInProcess.model";
import { parseVesselGroupingFile } from "../../service/parseExcelFile/vesselGroupingParserService";
import { UserUtility } from "../../utils/data/user.utility";
import { VesselProcessingStateService } from "../../service/api/vessel/vesselProcessingStateService";
import { storageConst } from "../../constants/global.constants";
import { VesselsInProcess } from '../../shared/models/shared/parseExcelFile/vesselGrouping.model';
import { parseVesselFile } from "../../service/parseExcelFile/vesselParserService";
import { VesselProcessingState } from "../../shared/models/vessel/vesselProcessingState.model";
import { PersistRawVesselFileAdapterService } from "../../service/api/vessel/persistRawVesselFileAdapterService";
import { VesselProcessingAction } from "../../shared/models/vessel/vesselProcessingAction.enum";
import { CommunicationAdapterFunctionService } from "../../service/api/vessel/communicationAdapterFunctionService";
import { VesselProcessingStatus } from '../../shared/models/vessel/vesselProcessingStatus.enum';
import { EnumUtility } from "../../utils/enum/enum.utility";
import { VesselImosModel } from "../../shared/models/vessel/vesselImos.model";
import { vesselToLowerCaseModel } from "../../utils/model/model.utility";

export function VesselManagementRoute() {
  const { selectedClientApp } = useSelectedClientApp();
  const { selectedCustomer } = useSelectedCustomer();
  const { signalRData } = useSignalRService();
  const isInitialRender = useRef(true);
  const { addToast } = useToastMessageService();
  const [processedVessel, setProcessedVessel] = useState<Vessel | null>(null);
  const [vesselsInProcess, setVesselsInProcess] = useState<VesselInProcessModel[]>([]);
  const { t } = useTranslation();

  const [disableButtons, setDisableButtons] = useState(true);

  const [activeButton, setActiveButton] = useState('uploadVesselButton');
  const [action, setAction] = useState<VesselProcessingAction>(VesselProcessingAction.Upload);

  const [selectedConfigFileJSON, setSelectedConfigFileJSON] = useState<string>("");
  const [selectedConfigFile, setSelectedConfigFile] = useState<File | null>(null);
  const [resetConfig, setResetConfig] = useState(false);
  const [resetVessel, setResetVessel] = useState(false);

  const [validationFailedConfig, setValidationFailedConfig] = useState(false);
  const [validationFailedVessel, setValidationFailedVessel] = useState(false);

  const colorBlack = theme.palette.info.dark;
  const colorWhite = theme.palette.text.primary;
  const colorGray = theme.palette.info.light;
  
  const selectedClientId: string = ClientUtility.GetSelectedClientId() ?? "";
  const selectedCustomerId: string = CustomerUtility.GetSelectedCustomerId() ?? "";
  const userId: string = UserUtility.GetUserId();
  
  const localStorageKey_VesselInProcess = storageConst.VESSELS_IN_PROCESS + '_' + userId + '_' + selectedClientId + '_' + selectedCustomerId;

  const vesselProcessingStateService = new VesselProcessingStateService();

  const persistRawVesselFileAdapterService = new PersistRawVesselFileAdapterService();

  const communicationAdapterFunctionService = new CommunicationAdapterFunctionService();

  useEffect(() => {
    setResetConfig(true);
    setResetVessel(true);
    setSelectedConfigFileJSON("");
    setSelectedConfigFile(null);
    setValidationFailedConfig(false);
    setValidationFailedVessel(false);
    const timer = setTimeout(() => {
      setResetConfig(false);
      setResetVessel(false);
    }, 0);
    return () => clearTimeout(timer);
  }, [activeButton]);

  useEffect(() => {
    setDisableButtons(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp]);

  useEffect(() => {
    if (selectedClientApp && selectedCustomer?.id) {
      setDisableButtons(false);
    } else {
      setDisableButtons(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  const handleButtonClick = (buttonId: string) => {
    setActiveButton(buttonId);
  };

  const buttonStyles = (buttonId: string) => ({
    color: activeButton === buttonId ? colorBlack : colorWhite,
    backgroundColor: activeButton === buttonId ? colorWhite : colorBlack,
  });

  useEffect(() => {
    
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!NotificationMessagesShouldBeProcessed()) return;

    ProcessVesselProcessingDLQ();
    ProcessVesselProcessingResposne();
    ProcessVesselProcessingStatus();

    // eslint-disable-next-line
  }, [signalRData]);

  useEffect(() => {
    setResetConfig(true);
    setResetVessel(true);
    setValidationFailedConfig(false);
    setValidationFailedVessel(false);
    initializingVesselInProcessGrid().then((result) => {
      setVesselsInProcess(result);
    });
    
    // eslint-disable-next-line
  }, [selectedCustomer]);

  function ProcessVesselProcessingDLQ() {
    if (
      signalRData?.Target === SignalRTargets.VesselDlqBlobTargetName ||
      signalRData?.Target === SignalRTargets.VesselDlqParserTargetName ||
      signalRData?.Target === SignalRTargets.VesselDlqPersistTargetName
    ) {
      addToast(
        "error",
        t("routes.vessels.notification.error.vessel_processing"),
        signalRData?.Data.errors
      );
    }
  }

  function ProcessVesselProcessingResposne() {
    if (signalRData?.Target === SignalRTargets.VesselQueueResponseTargetName) {
      if (signalRData?.Data.errors.length === 0) {

        setProcessedVessel(vesselToLowerCaseModel(signalRData?.Data.vessel));
        addToast(
          "info",
          t("routes.vessels.notification.info.vessel_added_created")
        );
      } else {
        addToast(
          "error",
          t("routes.vessels.notification.error.vessel_processing"),
          signalRData?.Data.errors
        );
      }
    }
  }

  function ProcessVesselProcessingStatus() {
    if (signalRData?.Target === SignalRTargets.VesselProcessingStatusTargetName) {

      const index = vesselsInProcess.findIndex(vessel => vessel.ImoNumber === signalRData.Data.vesselImoNumber);

      if (index !== -1) {
        vesselsInProcess[index].Name = signalRData.Data.vesselName;
        vesselsInProcess[index].Status = signalRData.Data.vesselState;
        vesselsInProcess[index].Error = signalRData.Data.errorMessage
      } else {
        const vesselInProcessModel: VesselInProcessModel = {
          Id: signalRData.Data.vesselImoNumber,
          Name: signalRData.Data.vesselName,
          ImoNumber: signalRData.Data.vesselImoNumber,
          Status: signalRData.Data.vesselState,
          Error: signalRData.Data.errorMessage
        };
        
        vesselsInProcess.push(vesselInProcessModel);
      }
      
      setVesselsInProcess(vesselsInProcess);
    }
  }

  function NotificationMessagesShouldBeProcessed(): boolean {
    return (
      ClientUtility.IsSelectedClientApp(signalRData?.Data?.clientId) &&
      CustomerUtility.IsSelectedCustomer(signalRData?.Data?.customerId) &&
      UserUtility.IsSelectedUserId(signalRData?.Data?.userId)
    );
  }


  const initializingVesselInProcessGrid = async () : Promise<VesselInProcessModel[]> => {

    let vesselsInProcessModel: VesselInProcessModel[] = [];

    if (selectedClientId && selectedCustomerId) {
      const localStorageVesselInProcess = localStorage.getItem(localStorageKey_VesselInProcess);

      let vesselsInProcess: VesselsInProcess[] = [];

      if (localStorageVesselInProcess) {
        try {
          vesselsInProcess = JSON.parse(localStorageVesselInProcess) as VesselsInProcess[];
        } catch (error) {
          console.error('Error parsing local storage data:', error);
        }
      }
      
      vesselsInProcess.forEach(item => {
        let existingIndex = vesselsInProcessModel.findIndex(vessel => vessel.ImoNumber === item.VesselImo);

        let vesselInProcessModel: VesselInProcessModel = {
          Id: item.VesselImo,
          Name: item.Name,
          ImoNumber: item.VesselImo,
          Status: item.Status,
          Error: item.Errors
        };

        if (existingIndex !== -1) {
          vesselsInProcessModel[existingIndex] = vesselInProcessModel;
        } else {
          vesselsInProcessModel.push(vesselInProcessModel);
        }
      });

      let vesselProcessingState : VesselProcessingState[] = [];
      try {
        vesselProcessingState = await vesselProcessingStateService.getVesselProcessingStateAsync(selectedClientId, selectedCustomerId);
      }
      catch (error) {
        console.error('Error getting vessel processing state:', error);
      }

      if (vesselProcessingState.length !== 0) {
        vesselProcessingState.forEach((item) => {
          let existingIndex = vesselsInProcessModel.findIndex(
            (vessel) => vessel.ImoNumber === item.VesselImoNumber
          );

          let vesselInProcessModel: VesselInProcessModel = {
            Id: item.VesselImoNumber,
            Name: item.VesselName,
            ImoNumber: item.VesselImoNumber,
            Status: item.VesselState,
            Error: item.ErrorMessage,
          };

          if (existingIndex !== -1) {
            vesselsInProcessModel[existingIndex] = vesselInProcessModel;
          } else {
            vesselsInProcessModel.push(vesselInProcessModel);
          }
        });
      }
      else {
        localStorage.removeItem(localStorageKey_VesselInProcess);
        vesselsInProcessModel = [];
      }
    }

    return vesselsInProcessModel;
  }

  const handleConfigFileSelect = async (file: File | null) => {
    
    if (file === null || file === undefined) {
      setResetConfig(true);
      setResetVessel(true);
    }
    
    if (file) {
      const vesselGroupingParseResult = await parseVesselGroupingFile(file);
      
      if (vesselGroupingParseResult.Errors.length > 0) {
        addToast("error", t("service.parseexcelfile.error_parsing_excel_file"), vesselGroupingParseResult.Errors);
        setSelectedConfigFileJSON("");
        setSelectedConfigFile(null);
        setValidationFailedConfig(true);
        return;
      }

      setValidationFailedConfig(false);

      setSelectedConfigFileJSON(JSON.stringify(vesselGroupingParseResult));
      setSelectedConfigFile(file);
      const itemsForVesselInGrouping : VesselInProcessModel[] = vesselGroupingParseResult.VesselGrouping.map(item => ({
        Id: item.VesselImo,
        Name: "",
        ImoNumber: item.VesselImo,
        Status: item.Status,
        Error: item.Errors.join(", ")
      }));
      setVesselsInProcess(itemsForVesselInGrouping);

      const jsonObject = vesselGroupingParseResult.VesselGrouping.map(item => ({
        VesselImo: item.VesselImo,
        Name: "",
        Status: item.Status,
        Errors: item.Errors.join(", ")
      }));

      const jsonString = JSON.stringify(jsonObject);

      const localStorageKey: string = storageConst.VESSELS_IN_PROCESS + '_' + userId + '_' + selectedClientId + '_' + selectedCustomerId;

      localStorage.setItem(localStorageKey, jsonString);
    }
  };

  const handleVesselFileSelect = async (file: File | null) => {
    if (file === null || file === undefined) {
      setResetVessel(true);
    }
    if (file) {
      const result = await parseVesselFile(file);

      if (result.Errors.length > 0) {
        addToast("error", t("service.parseexcelfile.error_parsing_excel_file"), result.Errors);
        setValidationFailedVessel(true);
        return;
      }

      setValidationFailedVessel(false);
      
      let response = await persistRawVesselFileAdapterService.persistRawVesselFileAdapterAsync(selectedClientId, selectedCustomerId, action, file, selectedConfigFileJSON);

      if (response.success.length > 0) {
        addToast("success", t(response.success[0]));
      } else {
        addToast("error", t("routes.vessels.error_submitting_vessel_data"), response.errors);
      }
      
    }
  };

  const handleSubmit = async () => {

    const imoNumbers = vesselsInProcess
    .filter(vessel =>
      vessel.Status ===
      (EnumUtility.GetEnumMemberName(
        VesselProcessingStatus,
        VesselProcessingStatus.ReadyToSubmit
      ) ?? "")
    )
    .map(vessel => vessel.ImoNumber);

    const result: VesselImosModel = {
      vesselImos: imoNumbers,
    };
    
    const vesselImos = JSON.stringify(result);

    let response = await communicationAdapterFunctionService.communicationAdapterFunctionAsync(selectedClientId, selectedCustomerId, vesselImos);

      if (response.success.length > 0) {
        addToast("success", t(response.success[0]));
      } else {
        addToast("error", t("routes.vessels.error_submitting_vessel_data"), response.errors);
      }
  };

  const readyToSubmit = (): boolean => {
    if (vesselsInProcess.length === 0) {
      return false;
    }
    return vesselsInProcess.some(vessel => vessel.Status === (EnumUtility.GetEnumMemberName(
      VesselProcessingStatus,
      VesselProcessingStatus.ReadyToSubmit
    ) ?? ""));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ButtonGroup
        variant="outlined"
        aria-label="Vessels button group"
        sx={{ marginTop: "10px", marginBottom: "2px" }}
      >
        <Button
          id="uploadVesselButton"
          onClick={() => {
            handleButtonClick("uploadVesselButton");
            setAction(VesselProcessingAction.Upload);
          }}
          sx={buttonStyles("uploadVesselButton")}
        >
          {t("routes.vessels.vessels_management_route.upload_vessels_upper")}
        </Button>
        <Button
          id="updateVesselButton"
          onClick={() => {
            handleButtonClick("updateVesselButton");
            setAction(VesselProcessingAction.Update);
          }} 
          sx={buttonStyles("updateVesselButton")}
        >
          {t("routes.vessels.vessels_management_route.update_vessels_upper")}
        </Button>
      </ButtonGroup>

      <Box sx={{ marginTop: "15px", display: "flex" }}>
        <Box flex={1} width="40%" sx={{ marginRight: "25px" }}>
          <UploadFile
            buttonCaption={t(
              "routes.vessels.vessels_management_route.upload_vessel_config"
            )}
            allowedFileExtensions={["xlsx", "xlsm"]}
            onFileSelected={handleConfigFileSelect}
            disabled={disableButtons}
            reset={resetConfig}
            validationFailed = {validationFailedConfig}
          />
          <Typography
            variant="body1"
            sx={{ color: colorGray, marginLeft: "5px" }}
          >
            {activeButton === "uploadVesselButton"
              ? t(
                  "routes.vessels.vessels_management_route.this_step_is_mandatory"
                )
              : "\u00A0"}
          </Typography>
          <UploadFile
            buttonCaption={t(
              "routes.vessels.vessels_management_route.upload_vessels"
            )}
            allowedFileExtensions={["xlsx", "xlsm"]}
            onFileSelected={handleVesselFileSelect}
            disabled={
              disableButtons ||
              (activeButton === "uploadVesselButton" &&
                selectedConfigFile === null)
            }
            reset={resetVessel}
            validationFailed = {validationFailedVessel}
          />
        </Box>

        <Box flex={1} width="40%" sx={{ marginRight: "25px" }}></Box>
      </Box>

      <Box sx={{ marginTop: "25px", display: "flex" }}>
        <Box flex={1} width="40%" sx={{ marginRight: "25px" }}>
          <StyledHeaderWithoutDivider
            key="customer_vessels"
            label={t(
              "routes.vessels.vessels_management_route.existing_vessels"
            )}
          />
          <DataGridVessel item={processedVessel} />
        </Box>
        <Box flex={1} width="40%" sx={{ marginLeft: "25px" }}>
          <StyledHeaderWithoutDivider
            key="customer_vessels"
            label={t(
              "routes.vessels.vessels_management_route.vessels_in_process"
            )}
          />
          <DataGridVesselsInProcess item={vesselsInProcess} />
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}
      >
        <PrimaryButton
          id="SubmitButton"
          type="submit"
          variant="contained"
          sx={{ minWidth: "250px" }}
          onClick={handleSubmit}
          disabled={!readyToSubmit()}
        >
          Submit
        </PrimaryButton>
      </Box>
    </Box>
  );
}