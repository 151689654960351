import { storageConst } from "../../constants/global.constants";
import { v4 as uuidv4 } from "uuid";

export class SessionUtility {
  public static GetSessionId(): string {
    let sessionId = localStorage.getItem(storageConst.SESSION_ID);
    return sessionId ?? "undefined";
  }

  public static SetSessionId(): void {
    localStorage.setItem(storageConst.SESSION_ID, uuidv4());
  }
}