import { Vessel, VesselCapitalized } from "../../shared/models/shared/vessel/vessel.model";

function vesselToLowerCaseModel(vessel: VesselCapitalized): Vessel | null {
  if (vessel == null) {
    return null;
  }

  return {
    imo: vessel.Imo,
    type: vessel.Type,
    name: vessel.Name,
    deadweight: vessel.Deadweight,
    createdDateTime: vessel.CreatedDateTime ? new Date(vessel.CreatedDateTime.Seconds * 1000 + vessel.CreatedDateTime.Nanos / 1000000) : undefined,
    updatedDateTime: vessel.UpdatedDateTime ? new Date(vessel.UpdatedDateTime.Seconds * 1000 + vessel.UpdatedDateTime.Nanos / 1000000) : undefined,
  };
}

export { vesselToLowerCaseModel };