import { VesselGroupingType } from "./modelVesselGrouping.model";

export const VesselGroupingTypes: Array<VesselGroupingType> = [ "Organization" ]

export interface VesselGrouping {
  id: string;
  type: VesselGroupingType;
  order?: number;
  category?: string
  name?: string;
  description?: string;
}

export {};