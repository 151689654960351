import {
  Box,
  Divider,
  Stack, 
  Typography 
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PrimaryButton} from "../../../styled/button/styledButtonPrimary.component";
import React from "react";
import {useToastMessageService} from "../../../../context/toast/toastMessage.Context";
import {ClientUtility} from "../../../../../utils/data/client.utility";
import {CustomerUtility} from "../../../../../utils/data/customer.utility";

interface ComponentProperties {
  onAddCatalogButtonClickedHandler: () => void;
}

export const VesselGroupingCatalogHeader = (props: ComponentProperties) => {
  const {t} = useTranslation();
  const {addToast} = useToastMessageService();
  const addButtonClickHandler = () => {
    if(!selectedClientId) {
      addToast("error", t("error.no_client_selected"));
      return;
    }
    if(!selectedCustomerId) {
      addToast("error", t("error.no_customer_selected"));
      return;
    }

    props.onAddCatalogButtonClickedHandler();
  };

  const selectedClientId: string = ClientUtility.GetSelectedClientId() ?? "";
  const selectedCustomerId: string = CustomerUtility.GetSelectedCustomerId() ?? "";

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h2" component="h2">{ "Vessel Grouping Catalog" }</Typography>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          direction="row"
          spacing={1}
        >
          <PrimaryButton
              id="addVesselGroupingCatalogButton"
              onClick={addButtonClickHandler}
          >
            { "Create" }
          </PrimaryButton>
        </Stack>
      </Stack>
      <Divider textAlign="right" sx={{ mb: 2 }}></Divider>
    </Box>
  );
};

export default VesselGroupingCatalogHeader;