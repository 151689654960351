import { Client } from "../../../shared/models/client/client.model";
import { BaseService } from "../BaseService";
import { paths } from "./clientService.constants";
import { clientConstants } from "../../../constants/global.constants";
import { SessionUtility } from "../../../utils/data/session.utility";
import { UserUtility } from "../../../utils/data/user.utility";
import { headerKey as generalHeaderKey } from "../common.constants";
import {headerKey} from "../port/portService.constants";
import {appConfig} from "../../../config/configLoader";

export class ClientService extends BaseService {
  public async getClientsService() {
    return this.get<Client[]>(
      paths.ONBOARDING_MIDDLEWARE_GET_CLIENTS,
      {
        headers: {
        [headerKey.CC_ACTIVE_CLIENT]: appConfig.MSAL_CONFIG_CLIENT_ID,
        [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
        [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
        }
      }
    );
  }

  public async getClientLastSyncDateTimeService(clientId: string): Promise<Date> {
    const responseData = await this.get<Client[]>(
      paths.ONBOARDING_MIDDLEWARE_GET_CLIENTS,
      {
        headers: {
        [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
        [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
        }
      }
    );

    const client = responseData?.data?.find(
      (client) =>
        client.ClientId === clientId &&
        client.Tags?.toLowerCase().includes(clientConstants.MDF_KEYWORD)
    );

    return client?.LastSyncDatetime ? new Date(client.LastSyncDatetime) : new Date(new Date().toUTCString());
  }

  public async isClientMDFService(clientId: string): Promise<boolean> {
    const responseData = await this.get<Client[]>(
      paths.ONBOARDING_MIDDLEWARE_GET_CLIENTS,
      {
        headers: {
        [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
        [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
        }
      }
    );

    const client = responseData?.data?.find(
      (client) =>
        client.ClientId === clientId &&
        client.Tags?.toLowerCase().includes(clientConstants.MDF_KEYWORD)
    );

    return !!client;
  }
}